<template>
	<div class="category-container">
		<!-- 头部 -->
		<div class="category-container-header" :style="{ '--background': pseudoElementBg }">
			<div class="text-title">{{$t('category.category')}}</div>
		</div>
		<div class="swipe">
			<div class="imgs">
				<!-- 轮播图 -->
				<Swipe type-name="deProCategories" />
			</div>
		</div>
		<!-- 搜索 -->
		<SearchBox />
		<!-- 分类列表-->
		<CategoryList style="min-height:100px" />
		<WaterMark />
	</div>
</template>

<script>
	import TopNavBar from './components/TopNavBar.vue';
	import Swipe from '@/components/common/Swipe.vue';
	import SearchBox from './components/SearchBox.vue';
	import CategoryList from './components/CategoryList.vue';
	import {
		setBgColor
	} from '@/utils';
	export default {
		name: 'Category',
		components: {
			TopNavBar,
			Swipe,
			SearchBox,
			CategoryList
		},
		data() {
			return {};
		},
		computed: {
			pseudoElementBg() {
				return setBgColor('categorieColor1', 'categorieColor2');
			}
		},
		mounted() {}
	};
</script>

<style lang="less" scoped>
	.category-container {
		padding-bottom: 100px;
		background-color: #ffffff;
		border: none;

		.category-container-header {
			width: 100%;
			height: 199px;
			position: relative;
			z-index: 0;
			overflow: hidden;
			background: var(--background);
			border: none;

			.text-title {
				position: absolute;
				top: 21px;
				width: 100%;
				text-align: center;
				color: #ffffff;
				font-size: 15px;
				font-weight: 400;
			}
		}

		.category-container-header::after {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			content: '';
			height: 199px;
			background-image: url(../../assets/img/category/2.png);
			background-size: 100% 100%;
			border: none;
		}

		&::before {
			content: '';
			display: table;
		}

		.swipe {
			position: absolute;
			width: 100%;
			top: 55px;
			display: flex;
			justify-content: center;
			z-index: 998;

			.imgs {
				width: 100%;
				height: 250px;
				border-radius: 10px;
			}
		}

		.top-nav-bar {
			position: fixed;
			top: 0;
			width: 100%;
			height: 60px;
			z-index: 999;
		}

		.footer-img {
			width: 230px;
			height: 42px;
			margin: 38px auto 35px;

			&>img {
				width: 100%;
				height: 100%;
			}
		}
	}
</style>
