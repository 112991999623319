<template>
	<div class="category-list-box">
		<div v-for="(item, index) of categoryList" :key="item.uuid" >
			<div style="display: flex; justify-content: center;" @click="show(item,index)" >
				<div class="category-list">
					<img class="icon-category" :src="item.filePath" alt="" />
					<div class="category-rt-box">
						<div class="category-content">
							<span class="category-typeName">{{ item.typeName }}</span>
							<span class="category-enName">{{ item.enName }}</span>
						</div>
						<img class="file-ico duration05" :class="item.show?'transform45':''" :src="item.fileIoc" alt="" />
					</div>
				</div>
			</div>

			<div class="child duration05" v-if="item.show">
				<div v-for="(it, i) in item.childs" @click="goProAllList(i,'child',it);" :key="i" :style="{ 'background': setBgColor(it.colorCode)}" class="child-btn">{{ it.typeName }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getAppProTypeList } from '@/api';
export default {
	name: 'CategoryList',
	data() {
		return {
			categoryList: [],
		};
	},

	created() {
		this.getAppProTypeList();
	},
	methods: {
		show(item,index){
			if(item.childs.length > 0){
				item.show = !item.show;
				this.$forceUpdate()
			}else{
				this.goProAllList(index,'child',item);
			}
		},
		setBgColor(colorCode){
			let bgColor = colorCode.split(',');
			let carouselColor1 = bgColor[0];
			let carouselColor2 = bgColor[1];
			return  'linear-gradient(90deg,' + carouselColor1 + ',' + carouselColor2 + ')'
		},
		getAppProTypeList() {
			const params = {
				shopUuid: this.$store.state.shopUuid
			};
			getAppProTypeList(params).then(res => {
				if (res.state == 100) {
					this.categoryList = [];
					res.items.map(resp =>{
						resp.show = false;
					})
					this.categoryList = res.items;
				}
			});
		},
		
		goProAllList(index,type,data) {
			this.$store.commit('SET_PRO_ACTIVE', index);
			this.$store.commit('setProType', true);
			if(type == 'child'){
			  this.$router.push({ name: 'ProAllList',query:{prtName:data.typeName,shopUuid:this.$store.state.shopUuid} });
			}else{
				this.$router.push({ name: 'ProAllList' ,query:{shopUuid:this.$store.state.shopUuid}});
			}
		}
	}
};
</script>

<style lang="less" scoped>
.child {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 7px 29px;
	.child-btn {
		width: 49%;
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #FFFFFF;
		border-radius: 5px;
		margin-top: 8px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding-left: 4px;
	}
}
.transform45{
    transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
    -moz-transform:rotate(90deg);
}
.duration05{
	transition-duration:0.5s;
}

.category-list {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 7px 29px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	width: 347px;
	.icon-category {
		// width: 105px;
		height: 75px;
		position: relative;
		z-index: 1;
	}

	.category-rt-box {
		position: relative;
		display: flex;
		align-items: center;
		width: 256px;
		height: 75px;
		background: #ffffff;
		padding-left: 13px;
		border-radius: 0 10px 10px 0;
		// &::before {
		//   content: "";
		//   width: 80px;
		//   height: 80px;
		//   background-color: #f6f6f6;
		//   // border-radius: 0 61px 61px 0;
		//   border-radius: 50%;
		//   position: absolute;
		//   left: -60px;
		// }

		.category-content {
			display: flex;
			flex-direction: column;
			width: 160px;
			.category-typeName {
				font-size: 16px;
				height: 18px;
				line-height: 18px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #646464;
				text-align: left;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.category-enName {
				margin-top: 6px;
				font-size: 12px;
				line-height: 12px;
				font-family: PingFang SC;
				color: #c8c8c8;
				text-align: left;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: 400;
			}
		}

		.file-ico {
			position: absolute;
			right: 13px;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			border: none;
			outline: none;
		}
	}
}
</style>
