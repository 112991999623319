<template>
  <div class="search-box">
    <div class="search-top" @click="goProAllList">
      <img class="left" src="@/assets/img-v3/new-home/searchIcon.png">
      <span>{{$t('home.search')}}</span>
	  <van-icon name="scan"  class="right" color="#1CDDA0"  size="20" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBox',
  methods: {
    goProAllList() {
      this.$store.commit('SET_PRO_ACTIVE', 0)
      this.$router.push({ name: 'ProAllList',query:{shopUuid:this.$store.state.shopUuid} })
    }
  }
}
</script>

<style lang="less" scoped>
.search-box {
  // width: 375px;
  height: 87px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 115px;
  .search-top {
    width: 300px;
    height: 33px;
    background: #f4f4f4;
    border-radius: 15px;
    display: flex;
    align-items: center;
    position: relative;
    /*text-align: 10px;*/
    .left {
      margin: 0 8px 0 13px;
    }

    .right {
      position: absolute;
      right: 12px;
    }

    & > span {
      width: 210px;
      height: 33px;
      text-align: center;
	  display: flex;
	  align-items: center;
      white-space: nowrap;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #787878;
    }

    & > img {
      width: 16.5px;
      height: 16.5px;
    }
  }
}
</style>
